$(document).ready(function() {
    var controller = new ScrollMagic.Controller();

    var isMobile = ($(window).width() <= 740);
    var isSmall = ($(window).width() <= 1200);
    var isTablet = ($(window).width() <= 800);

    var pinHeader = function() {
		var pinScene = new ScrollMagic.Scene({
	    triggerElement: null,
	    triggerHook: 100,
		}).setPin("#header", {
	    	pushFollowers: false,
	    	spacerClass: 'header-spacer'
		}).addTo(controller);    	
    }

    var tweenMenu = function() {
      var menuTween = new TimelineMax()
      .to('#header', 4, {top: '0', height:'60px', borderTop: '5px solid #9BEC7F', backgroundColor: "#fff"})
      .to('#O1', 4, {morphSVG: '#mark-center'}, 0)
      .to('#K1', 1, {morphSVG: '#mark-left'}, 0)
      .to('#END', 4, {morphSVG: '#mark-right'}, 0)
      .to('#W1', 1, {opacity:0}, 0)
      .to('#O2', 1, {opacity:0}, 0)
      .to('#O3', 1, {opacity:0}, 0)
      .to('#D1', 1, {opacity:0}, 0)
      .to('#DOT', 1, {opacity:0}, 0)
      .to('#D2', 1, {opacity:0}, 0)
      .to('#I1', 1, {opacity:0}, 0)
      .to('#G1', 1, {opacity:0}, 0)
      .to('#I2', 1, {opacity:0}, 0)
      .to('#T1', 1, {opacity:0}, 0)
      .to('#A2', 1, {opacity:0}, 0)
      .to('#L1', 1, {opacity:0}, 0)
      .to('#A1', 1, {opacity:0}, 0)
      .to('.top-nav-link', 4, {color: '#494653'}, 0)
      .to('.logo-wrapper', 4, {width:'175px'}, 0);

      if (!isMobile) { 
          menuTween
          .to('.items-wrapper', 4, {maxWidth:'90%'}, 0);
      }

      var containerScene = new ScrollMagic.Scene({
        triggerElement: '#header',
        triggerHook: 0,
        duration: 200,
      }).setTween(menuTween)
      .addTo(controller);      
    }

    tweenMenu();
    pinHeader();

});